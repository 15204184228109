import css from './index.module.scss'
import img from './explode.png'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { animated, useChain, useTransition } from '@react-spring/web'
import { Explode } from './explode'
import { useTranslation } from 'react-i18next'

export const Banner = () => {
    const { t } = useTranslation()
    const [h3ref, h3Style] = useOpacity(1000)

    useChain([h3ref], [0.2])

    return <div className={css.banner}>
        <Explode></Explode>
        <animated.h3 style={h3Style} className={css.title}>{t('核心技术')}</animated.h3>
    </div>
}