import { Info } from "../Info"
import css from './index.module.scss'
import pic1 from './img/调度平台-图1 .png'
import pic2 from './img/调度平台-图2 .png'
import pic3 from './img/调度平台-图3.png'
import pic4 from './img/调度平台-图4.png'


const items = [{
    title: '调度参数learning化', msg: '对商超门店的运营历史数据，利用机器学习的方法训练得到最优的调度策略参数模型，并且根据门店实际运营数据反馈，持续对模型参数进行更新。',
    pic: pic1
}, {
    title: '策略热更新', msg: '采用异地多活的方式实现调度策略模块的分布式多实例部署，提高系统的稳定性和可靠性的同时，又能保证策略的随时更新升级、不停服替换。',
    pic: pic2
}, {
    title: '多策略同时在线', msg: '多种运营调度策略以服务的形式同时在线，最高支持订单级别的调度策略切换。',
    pic: pic3
}, {
    title: '门店单量智能决策', msg: '对门店单量建立模型，实现单量的智能预测。由系统自动化决策不同的调度策略，提高门店的智能化决策水平。',
    pic: pic4
}]

export const Scheduler = () => <Info 
    title="调度平台"
    desc="调度平台是针对商超配送领域的智能化运营工具。白犀牛调度平台包括订单管理、无人车智能调度、人机交互等核心模块，可有效实现对无人车的数字化运营。"
    headerPlus={<div className={css['title-pic']}>
        <div className={css.car1}></div>
        <div className={css.car2}></div>
    </div>}
    items={items}
    className={css.scheduler}
/>