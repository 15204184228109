import { useMenuColor } from "../hooks/useMenuColor.hook"
import { Banner } from "./Banner"
import { DeepLearn } from "./DeepLearn"
import { Hardware } from "./Hardware"
import { Perception } from "./Perception"
import { Planning } from "./Planning"
import { Scheduler } from "./Scheduler"
import { Simu } from "./Simu"

export const Technology = () => {
    const ref = useMenuColor({ color: '#1f1f1f', background: '#fff' })
    return <div ref={ref}>
        <Banner />
        <Perception />
        <DeepLearn />
        <Hardware />
        <Simu />
        <Planning />
        <Scheduler />
    </div>
}