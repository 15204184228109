import css from './index.module.scss'
import p01 from './img/001.png'
import p02 from './img/002.png'
import p03 from './img/003.png'
import p04 from './img/004.png'
import p05 from './img/005.png'
import p06 from './img/006.png'
import p07 from './img/007.png'
import p08 from './img/008.png'
import p09 from './img/009.png'
import p10 from './img/010.png'
import p11 from './img/011.png'
import p12 from './img/012.png'
import p13 from './img/013.png'
import p14 from './img/014.png'
import p15 from './img/015.png'
import p16 from './img/016.png'
import p17 from './img/017.png'
import p18 from './img/018.png'
import p19 from './img/019.png'
import p20 from './img/020.png'
import p21 from './img/021.png'
import p22 from './img/022.png'
import p23 from './img/023.png'
import p24 from './img/024.png'
import { useState } from 'react'
import { animated, useInView, useSpring } from '@react-spring/web'
import { useDeepCompareEffect, useInterval } from 'ahooks'
import { useEnAbleClass } from '../../hooks/useEnAbleClass.hook'
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'

const list = [
    { title: '国家高新技术企业', pic: p01 },
    { title: '中关村高新技术企业', pic: p02 },
    { title: '中关村金种子企业', pic: p03 },
    { title: '北京市“专精特新”中小企业', pic: p04 },
    { title: 'ISO 9001 质量管理体系认证', pic: p05 },
    { title: '中国道路交通安全协会会员', pic: p06 },
    { title: '深圳市智能交通行业协会常务理事单位', pic: p07 },
    { title: '团体标准《低速无人车城市商业运营安全管理规范》主要参编单位', pic: p08 },
    { title: '低速无人驾驶产业联盟理事单位', pic: p09 },
    { title: '2022年：上榜2022年度首批上海市智能网联汽车示范应用创新项目（揭榜挂帅）', pic: p10 },
    { title: '2022年度：安徽省级智能网联汽车创新场景示范奖', pic: p11 },
    { title: '合肥市首批低速无人车商业运营许可', pic: p12 },
    { title: '阳泉市智能网联汽车自动驾驶出行商业化试点许可', pic: p13 },
    { title: '2022同城即时物流技术创新案例', pic: p14 },
    { title: '新冠肺炎疫情防控突出贡献证书（由武汉东湖新技术开发区光谷科技会展中心方舱医院临时委员会颁发）', pic: p15 },
    { title: '2021年：中关村5G创新应用大赛总决赛二等奖', pic: p16 },
    { title: '2021年：长三角智慧城市建设项目突出贡献奖', pic: p17 },
    { title: '2021年：中国同城即时物流科技创新案例', pic: p18 },
    { title: '2020年：万达集团与华为联合举办的“5G创新应用大赛十强”', pic: p19 },
    { title: '2022年：智能汽车2022年度无人车风云榜十强企业', pic: p20 },
    { title: '2022年：智能汽车2022年度无人车风云榜推荐产品', pic: p21 },
    { title: '2022年：2022年低速无人驾驶场景落地案例TOP50', pic: p22 },
    { title: '2020年：获中国同城即时物流行业创新应用奖', pic: p23 },
    { title: '通过莱茵ISO 22737低速车场景评估测试（LSAD）', pic: p24 }
]

export const Honor = () => {
    const [ current, setCurrent ] = useState(0)
    const [isStop, setIsStop] = useState(true)
    
    const {addEnCls} = useEnAbleClass(css)

    const [ulStyle, ulRef] = useSpring(() => ({
        from: {x: 0}
    }))

    useDeepCompareEffect(() => {
        ulRef.start({
            to: {x: -current * 536},
            config: {
                duration: 3000
            }
        })   
    }, [current])

    const [viewRef, isInView] = useInView({rootMargin: `0px 0px -400px 0px`})

    useDeepCompareEffect(() => {
        if (isInView) setIsStop(false)
    }, [isInView])

    useInterval(() => {
        if (isStop) return
        setCurrent((current + 1) % (list.length - 1))
    }, 3000)

    const { addMdAble } = useMiddleAble(css)

    return <div className={addMdAble(css['honor-wrapper'])}>
        <div className={css.honor}>
            <h3 ref={viewRef}>企业荣誉</h3>
            <animated.ul className={css.pics} style={ulStyle}>
                {list.map(item => <li>
                    <img src={item.pic} alt={item.title} onMouseEnter={() => setIsStop(true)} onMouseLeave={() => setIsStop(false)}/>
                    <div className={addEnCls(css.name)}>{item.title}</div>
                </li>)}
            </animated.ul>
            <ul className={css.dot} onMouseEnter={() => setIsStop(true)} onMouseLeave={() => setIsStop(false)}>
                {list.map((item, idx) => <li
                    className={current === idx ? css.active: ''}
                    onClick={() => setCurrent(idx)}
                ></li>)}
            </ul>
        </div>
    </div>
}