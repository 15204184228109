import { useTranslation } from 'react-i18next'
import css from './index.module.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDeepCompareEffect, useMemoizedFn, useResponsive } from 'ahooks'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { animated } from '@react-spring/web'
import { useEnAbleClass } from '../../hooks/useEnAbleClass.hook'
import classNames from 'classnames'

export const Footer = () => {
    const { t } = useTranslation()
    const { addEnCls } = useEnAbleClass(css)
    const [showCode, setShowCode] = useState(false)
    
    const resetScroll = useMemoizedFn(() => {
        window.scrollTo({left: 0, top: 0})
    })

    const [wordRef, wordStyle] = useOpacity()

    useDeepCompareEffect(() => {
        wordRef.set({opacity: 0})
        wordRef.start({opacity: 1, delay: 100})
    }, [showCode])

    const responsive = useResponsive()

    return <div className={css['footer-wrap']}>
        <div className={css.footer}>
            <div className={css.logo}></div>
            <ul className={`${css.menu}`}>
                <li><Link to="/technology" onClick={resetScroll}>{t('核心技术')}</Link></li>
                <li><Link to="/service" onClick={resetScroll}>{t('服务中心')}</Link></li>
                <li><Link to="/news" onClick={resetScroll}>{t('新闻资讯')}</Link></li>
            </ul>
            <ul className={`${css.us}`}>
                <li><Link to="/about-us" onClick={resetScroll}>{t('关于我们')}</Link></li>
                <li><Link to="/contact" onClick={resetScroll}>{t('联系我们')}</Link></li>
                <li><Link to="/contact" onClick={resetScroll}>{t('加入我们')}</Link></li>
            </ul>
            <ul className={css.contact}>
                <li><div className={css.key}>{t('电话')}</div><div className={css.value}>400-113-0423</div></li>
                <li><div className={css.key}>{t('邮箱')}</div><div className={css.value}>contacts@rino.ai</div></li>
                <li><div className={css.key}>{t('地址')}</div><div className={css.value}>{t('北京市 海淀区 花园东路11号 泰兴大厦 9层')}</div></li>
            </ul>
            <div className={css.line}></div>
            <div className={css.copyright}>©2021白犀牛智达(北京)科技有限公司      京ICP备19027919号-1</div>
            <div className={classNames({[css.wechat]: true, [css.middle]: !responsive.large})}
                onMouseEnter={() => setShowCode(true)}
                onMouseLeave={() => setShowCode(false)}>
                {showCode && <div className={addEnCls(css.code)}>
                    <animated.span style={wordStyle}>{t('扫码关注微信公众号获取最新资讯')}</animated.span>    
                </div>}
            </div>
        </div>
    </div>
}