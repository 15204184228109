import { useSpring, useSpringRef } from "@react-spring/web"

export const useOpacity = (duration = 800) => {
    const ref = useSpringRef()
    const style = useSpring({
        ref: ref,
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: {
            duration
        }
    })
    return [ref, style] as [typeof ref, typeof style]
}