import { useTranslation } from 'react-i18next'
import css from './point.module.scss'
import { useScale } from '../../hooks/useScale.hook'
import { animated } from '@react-spring/web'

export const Point: React.FC<{top: number, left: number, name: string}> = ({top, left, name}) => {
    const { t } = useTranslation()
    const [ , scaleStyle ] = useScale(100)

    const [ , ms ] = useScale(0)


    return <div className={css.point} style={{top, left}}>
        <animated.div className={css['point-o']} style={scaleStyle}></animated.div>
        <animated.div className={css['point-m']} style={ms}></animated.div>
        <animated.div className={css['point-i']} ></animated.div>
        <div className={css.name}>{t(name)}</div>
    </div>
}