import { animated, useChain, useInView } from '@react-spring/web'
import css from './index.module.scss'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { useTranslation } from 'react-i18next'
import pic2 from './img/商超即时配送-1.5.png'
import pic3 from './img/门店库存共享.png'
import pic4 from './img/快递网点接驳.png'
import pic5 from './img/一对一急送.png'
import pic6 from './img/冷链运输1.5.png'
import { useEnAbleClass } from '../../hooks/useEnAbleClass.hook'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { useResponsive } from 'ahooks'
import classNames from 'classnames'
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'

const cards = [
    { title: '商超即时配送', desc: '我们帮助商超客户完成外卖订单的配送。通过白犀牛智能调度平台，动态调度无人车、自动规划车辆行驶路线和接驳地点，并调度接驳人员实现货品送到客户家中。',
      pic: pic2},
    { title: '门店库存共享', desc: '我们使用无人车帮助零售客户实现多门店之间库存的动态调拨，从而使客户能够实现库存和实时销量的最佳匹配，提高潜在销售额的同时，降低潜在货损。',
      pic: pic3},
    { title: '快递网点接驳', desc: '我们为快递网点或转运中心提供不间断高频次接驳服务，从而可以实现快递网点到驿站的取送、网点之间错分件、急件的转运，从而让快递运输效率更高、成本更低。',
      pic: pic4},
    { title: '一对一急送', desc: '我们能够提供点对点即时送达服务，全程监控货品且无人参与，让时效更快、私密性更高。',
      pic: pic5},
    { title: '冷链运输', desc: '我们的车厢拥有自动控温功能，针对生鲜类即时商品可全程保证冷链运输。且支持模块化、个性化定制，同时可保持24小时不间断运营，满足全时段消费需求。',
      pic: pic6}
]

const Card: React.FC<{card: {
    title: string,
    desc: string,
    pic: string
}}> = ({card}) => {
    const [ ref, isInView ] = useInView({rootMargin: `0px 0px -150px 0px`})
    
    const [titleRef, titleStyle] = useOpacity()
    const [descRef, descStyle] = useOpacity()
    const [imgRef, imgStyle] = useOpacity()
    const { large } = useResponsive()

    useChain(isInView ? [titleRef, descRef, imgRef]: [], [0, 0.2, 0.4])

    const { t } = useTranslation()
    const { addEnCls }  = useEnAbleClass(css)
    const { addMdAble } = useMiddleAble(css)

    return <div className={addMdAble(css['card-wrap'])} ref={ref}>
        <div className={css.card}>
        <div className={css.line}>
            <animated.div className={addEnCls(css.title)} style={titleStyle}>{t(card.title)}</animated.div>
            <animated.div className={addEnCls(css.desc)} style={descStyle}>{t(card.desc)}</animated.div>
        </div>
        <animated.img src={card.pic} alt="" style={imgStyle}></animated.img>
    </div></div>
}

export const List = () => {
  const menuColorRef = useMenuColor({color: '#1f1f1f', background: '#fff'})
  return <div ref={menuColorRef}>
  {
      cards.map(card => <Card card={card} />)
  }
  </div>
}