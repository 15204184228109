import { useMemo, useRef, useState } from 'react'
import css from './index.module.scss'
import { useDeepCompareEffect, useInterval, useMemoizedFn, useMount, useSize, useThrottleFn } from 'ahooks'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { animated, useScroll, useSpring } from '@react-spring/web'
import { useTranslation } from 'react-i18next'
import { useEnAbleClass } from '../../hooks/useEnAbleClass.hook'
import classNames from 'classnames'
import { useResponsive } from 'ahooks';
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'


export const Info: React.FC<{
    title: string,
    desc: string,
    headerPlus: React.ReactNode,
    items: {
        title: string, msg: string,
        pic?: string,
        video?: string,
        children?: {title: string, pic: string}[]
    }[],
    className?: string
}> = ({
    title, desc, headerPlus,
    items, className
}) => {
    const { t } = useTranslation()
    const { addEnCls } = useEnAbleClass(css)
    const [current, setCurrent] = useState(0)
    const [subCurrent, setSubCurrent] = useState(0)

    const [detailPic, setDetailPic] = useState<{title: string, pic: string}|null>(null)

    const [isStop, setIsStop] = useState(true)

    const ref = useRef<HTMLDivElement>(null)

    const [isHover, setIsHover] = useState(false)

    const [imgClipStyle, imgClip] = useSpring(() => ({
        from: { clipPath: `inset(0 50% 0 50% round 20px)` }
    }))

    const {run: updateWidth} = useThrottleFn(() => {
        const { current } = ref;
        if (current) {
            const { top, height } = current.getBoundingClientRect()
            let c = 0
            if (top >= 0) {
                if ((window.innerHeight - top) > 500) {
                    c = 1- top / (window.innerHeight - 500)
                } else {
                    c = 0
                }
            }
            if (top<0 && (-top < height)) {
                c = 1 + (top / height)
            }
            if (c > 0.15) c = 1
            else c = c*3
            if (c === 1) {
                setIsStop(false)
            } else {
                setIsStop(true)
            }
            imgClip.start({to: {clipPath: `inset(0 ${(1-c)*30}% 0 ${(1-c)*30}% round 20px)`}})
        }
    }, {wait: 200, leading: true})

    useScroll({
        onChange: updateWidth})

    const next = useMemoizedFn(() => {
        if (isHover) return
        if (isStop) return
        setCurrent((current + 1) % items.length)
    })

    useInterval(next, 5000)

    useDeepCompareEffect(() => setSubCurrent(0), [current])

    const [imgRef, imgStyle] = useOpacity(300)

    const [childrenDetailImgRef, childrenDetailImgStyle] = useOpacity(300)

    useDeepCompareEffect(() => {
        imgRef.set({opacity: 0.0})

        const next = items[(current + 1) % items.length]

        if (next.video) {
            new Image().src = next.video
        }

        if (next.pic) {
            new Image().src = next.pic
        }
        if (next.children) {
            next.children.map(item => new Image().src = item.pic)
        }

    }, [current, subCurrent])

    const onImgLoad = useMemoizedFn(() => {
        imgRef.start({opacity: 1})
    })

    useDeepCompareEffect(() => {
        childrenDetailImgRef.set({opacity: 0})
        childrenDetailImgRef.start({opacity: 1})
    }, [detailPic])

    useMount(() => {
        imgRef.set({opacity: 1.0})
    })

    const responsive = useResponsive()

    const { addMdAble } = useMiddleAble(css)

    const menuCss = useMemo(() => {
        if (items.length === 3) return css.three
        if (items.length === 4) return css.four
        if (items.length === 6) return css.six
    }, [items])

    return <div className={`${className}`}>
        <div className={addMdAble(css.content)} ref={ref}>
            <div className={css.header}>
                {headerPlus}
                <div className={css.title}>{t(title)}</div>
                <div className={addEnCls(css.desc)}>{t(desc)}</div>
            </div>
            <div className={css.pics} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <ul className={classNames([css.menu, menuCss])}>{items.map((item, idx) => <li
                className={idx === current ? css.active: ''}
                onClick={() => setCurrent(idx)}
                onMouseEnter={() => setIsStop(true)} onMouseLeave={() => setIsStop(false)}
            ><span className={addEnCls(css.item)}>{t(item.title)}</span></li>)}</ul>
            <animated.div style={imgClipStyle} className={css['img-wrap']} onMouseEnter={() => setIsStop(true)} onMouseLeave={() => setIsStop(false)}>     
                {
                    items[current].children && <animated.ul style={imgStyle} className={css['children-new']} onMouseLeave={() => setDetailPic(null)}>
                        {items[current].children?.map((item, idx) => <li>
                            <div className={css.title}>{t(item.title)}</div>
                            <img src={item.pic} alt="" onLoad={onImgLoad} onClick={() => setDetailPic(item)}/>
                        </li>)}
                        {detailPic && <div className={css.mask} onClick={() => setDetailPic(null)}>
                            <animated.img src={detailPic.pic} alt="" className={css.detail} style={childrenDetailImgStyle}></animated.img>
                            <div className={css.label}>{t(detailPic.title)}</div>
                        </div>}
                    </animated.ul>
                }
                {
                    <animated.img style={{...imgStyle, display: items[current].pic ? 'block': 'none'}} src={items[current].pic ?? items[current].children?.[subCurrent].pic} alt="" onLoad={onImgLoad}/>
                }
                {
                    <video autoPlay muted src={items[current].video} style={{display: items[current].video ? 'block': 'none'}}>
                        <source src={items[current].video} />
                    </video>
                }
            </animated.div>
            <div className={addEnCls(css.msg)}>{t(items[current].msg)}</div>
            </div>
        </div>
    </div>
}