import { Trans, useTranslation } from 'react-i18next'
import css from './technology.module.scss'
import { animated, useChain, useInView, useSpring, useSpringRef, useSprings } from '@react-spring/web'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { useDeepCompareEffect, useMemoizedFn, useMount, useTimeout } from 'ahooks'
import { Link, To } from 'react-router-dom'
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'

export const TechnologyBlock = () => {
    const { t } = useTranslation()

    const [ref, inView] = useInView({once: true, rootMargin: '-50%'})

    const menuColorChangeRef = useMenuColor({
        color: '#171717',
        background: '#fff'
    })

    const [titleRef, titleStyle] = useOpacity()
    const [descRef, descStyle] = useOpacity()
    const [buttonRef, buttonStyle] = useOpacity()
    const [vehicleRef, vehicleStyle] = useOpacity()

    const { addMdAble } = useMiddleAble(css)

    const [[vc2Style, vc1Style], api] = useSprings(2, (idx) => ({
        from: { opacity: 0, scale: 0.0 },
        to: [{opacity: 1, scale: 1.0}, { opacity: 0, scale: 0.2 }, {opacity: 1, scale: 1.0}, { opacity: 0, scale: 0.2 }, {opacity: 1, scale: 1.0}],
        config: {
            duration: 1000
        },
        pause: !inView,
        delay: idx * 1000 * 0.7 + 1800
    }))

    useDeepCompareEffect(() => {
        inView && api.resume()
    }, [inView])

    useChain(inView ? [titleRef, descRef, buttonRef, vehicleRef]: [],
        [0, 0.5, 1.0, 1.5])

    const resetScroll = useMemoizedFn(() => {
        window.scrollTo({left: 0, top: 0})
    })

    return <div className={addMdAble(css.technology)} ref={ref}>
        <div className={css['technology-content']} ref={menuColorChangeRef}>
            <animated.h5 style={titleStyle}><Trans>{t('核心技术')}</Trans></animated.h5>
            <animated.div className={css.content} style={descStyle}>{t('我们拥有先进且多样化的自动驾驶能力，包括更加精确、可靠、安全的感知信息，更加优越的车辆性能以及更高精度的车辆控制。致力于从物流到出行实现更高效、安全、经济的自动驾驶。')}</animated.div>
            <Link to="/technology" onClick={resetScroll}>
                <animated.button className={css['more-btn']} style={buttonStyle}>{t('了解详情')}</animated.button>
            </Link>
            <animated.div className={css.vehicle} style={vehicleStyle}>
                <animated.div className={css['light-1']} style={vc1Style}></animated.div>
                <animated.div className={css['light-2']} style={vc2Style}></animated.div>
            </animated.div>
        </div>
    </div>
}