import { Banner } from './Banner'
import { Intorduce } from './Intorduce'
import { History } from './History'
import { Honor } from './Honor'

export const AboutUs = () => <>
    <Banner />
    <Intorduce />
    <History />
    <Honor />
</>