import css from './index.module.scss'
import { useRef, useState } from 'react'
import { useDeepCompareEffect, useInterval, useMemoizedFn } from 'ahooks'
import { animated, useSpring } from '@react-spring/web'

import p201904 from './img/2019／04.png'
import p201909 from './img/2019／09.png'
import p202002 from './img/2020／02.png'
import p202003 from './img/2020／03.png'
import p202005 from './img/2020／05.png'
import p202008 from './img/2020／08.png'
import p202009 from './img/2020／09.png'
import p202010 from './img/2020／10.png'
import p202105 from './img/2021／05.png'
import p202107 from './img/2021／07.png'
import p202112 from './img/2021／12.png'
import p2021122 from './img/2021／12-2.png'
import p202201 from './img/2022／01.png'
import p202203 from './img/2022／03.png'
import p2022032 from './img/2022／03(1).png'
import p202204 from './img/2022／04.png'
import p202209 from './img/2022／09.png'
import p2022092 from './img/2022／09(1).png'
import p202210 from './img/2022／10.png'
import p202211 from './img/2022／11.png'
import p202303 from './img/2023／03.png'
import p202304 from './img/2023／04.png'
import p202306 from './img/2023／06.png'
import p202308 from './img/2023／08.png'
import { Icon } from '../../widget/Icon'
import { useOpacityInView } from '../../hooks/useOpactiyInView.hook'
import { useTranslation } from 'react-i18next'
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'

const events = [{
    date: '2019/04', msg: '正式成立，辰韬资本种子轮投资',
    pic: p201904
}, {
    date: '2019/09', msg: '首款车下线，正式启动末端配送业务',
    pic: p201909
}, {
    date: '2020/02', msg: '驰援武汉抗击疫情，全球首家进入方舱医院协助进行物资配送的自动驾驶企业',
    pic: p202002
}, {
    date: '2020/03', msg: '辰韬资本追加投资',
    pic: p202003
}, {
    date: '2020/05', msg: '推出白犀严选，首创线上便利店模式，小程序下单无人车配送等全流程把控',
    pic: p202005
}, {
    date: '2020/08', msg: '与医药新零售头部企业叮当快药达成合作，中国首家将自动驾驶技术运用于药品运输企业',
    pic: p202008
}, {
    date: '2020/09', msg: '率先落地上海，运用自动驾驶技术开展物品配送，进行城市道路示范运营',
    pic: p202009
}, {
    date: '2020/10', msg: '与国内头部商超永辉超市达成战略合作，中国首家将自动驾驶技术运用于商超/生鲜无人配送企业',
    pic: p202010
}, {
    date: '2021/05', msg: '落地成都，运用自动驾驶技术为零售商超提供配送服务',
    pic: p202105
}, {
    date: '2021/07', msg: '线性资本领投，获近千万美元 Pre A 轮融资',
    pic: p202107
}, {
    date: '2021/12', msg: '索道资本、长安私人资本、基石资本、线性资本5000万元 Pre A+轮融资',
    pic: p202112
}, {
    date: '2021/12', msg: '获国家高新技术企业认证',
    pic: p2021122
}, {
    date: '2022/01', msg: '与天虹达成战略合作，深圳首家运用自动驾驶技术开展商超无人配送服务企业',
    pic: p202201
}, {
    date: '2022/03', msg: '大中华区首个通过L4级自动驾驶系统国际标准ISO 22737自动驾驶车辆场景评估测试企业',
    pic: p202203
}, {
    date: '2022/03', msg: '落地合肥，首家在合肥正式启动公开道路配送的自动驾驶企业',
    pic: p2022032
}, {
    date: '2022/04', msg: '上海抗疫保供，累计配送近2万件物资，服务约4万居民',
    pic: p202204
}, {
    date: '2022/09', msg: '获交通运输部首批智能交通先导应用试点',
    pic: p202209
}, {
    date: '2022/09', msg: '获合肥市首批低速无人车商业运营许可',
    pic: p2022092
}, {
    date: '2022/10', msg: '获北京市“专精特新”中小企业认证',
    pic: p202210
}, {
    date: '2022/11', msg: '与零售商超合作伙伴共创“虚拟前置仓”新业态',
    pic: p202211
}, {
    date: '2023/03', msg: '上海市首批智能网联汽车示范应用创新项目（揭榜挂帅）',
    pic: p202303
}, {
    date: '2023/04', msg: '完成A轮融资，辰韬资本领投，线性资本跟投',
    pic: p202304
}, {
    date: '2023/06', msg: '获深圳首批无人小车路权牌照',
    pic: p202306
}, {
    date: '2023/09', msg: '新车型Robovan下线，正式启动同城货运业务',
    pic: p202308
}]

const getYear = (d?: {date: string}) => {
    if (!d) return null;
    const { date } = d
    return date.split('/')[0]
}
const yearAddon: {[key: string]: string} = {}
for (let i=-1; i<events.length-1; i++) {
    const year = getYear(events[i])
    const nextYear = getYear(events[i+1])
    if (year !== nextYear)
    yearAddon[i+1+""] = nextYear!
}


export const History = () => {
    const { t } = useTranslation()

    const [historyRef, historyStyle, isInView] = useOpacityInView({rootMargin: `0px 0px -30% 0px`})

    const ref = useRef<HTMLUListElement>(null)
    const [currentIdx, setCurrentIdx] = useState(0)
    const [block, setBlock] = useState(0)
    const [stopTrick, setStopTrick] = useState(true)
    const [stop, setStop] = useState(true)

    const { addMdAble } = useMiddleAble(css)

    useDeepCompareEffect(() => {
        if (isInView) setStop(false)
    }, [isInView])

    useInterval(() => {
        const w = (ref.current?.offsetWidth ?? 1074)
        setBlock(w / events.length)
    }, 500, {immediate: true})

    const [width, setWidth] = useState(0)
    useDeepCompareEffect(() => {
        if (currentIdx === 0) {
            resetWidth()
        }
        else setWidth(block * Math.min(currentIdx + 1, events.length - 1))
    }, [block, currentIdx])

    const resetWidth = useMemoizedFn(() => {
        if (currentIdx === 0) {
            setPassedDurationAble(false)
            setWidth(0)
            setTimeout(() => {
                setPassedDurationAble(true)
                setWidth(block)
            }, 100)
        } else {
            setPassedDurationAble(true)
        }
    })

    useInterval(() => {
        if (stop) return;
        if (stopTrick) {
            setStopTrick(false)
            return;
        }
        setCurrentIdx((currentIdx + 1) % events.length)
    }, 3000)

    //const [imgRef, imgStyle] = useOpacity(300)

    const [imgStyle, imgRef] = useSpring(() => ({
        from: { opacity: 0, scale: 0 },
        to: { opacity: 0, scale: 1 }
    }))

    const [dateStyle, dateRef] = useSpring(() => ({
        from: {y: 50, opacity: 0.0},
        duration: 500
    }))

    const [contentStyle, contentRef] = useSpring(() => ({
        from: { opacity: 0.0 },
        duration: 900
    }))

    useDeepCompareEffect(() => {
        imgRef.set({opacity: 0.0, scale: 0})
        dateRef.set({y: 50, opacity: 0.0})
        contentRef.set({opacity: 0.0})

    }, [currentIdx])

    const prev = useMemoizedFn(() => {
        const p = currentIdx - 1
        if (p >= 0) setCurrentIdx(p)
        else setCurrentIdx(0)
        setStopTrick(true)
    })

    const next = useMemoizedFn(() => {
        const n = currentIdx + 1
        if (n < events.length) setCurrentIdx(n)
        else setCurrentIdx(events.length - 1)
        setStopTrick(true)
    })

    const set = useMemoizedFn((idx: number) => {
        setCurrentIdx(idx)
        setStopTrick(true)
    })

    const [passedDurationAble, setPassedDurationAble] = useState(true)

    const onImgLoad = useMemoizedFn(() => {
        setTimeout(() => {
            const [p] = imgRef.start({opacity: 1.0, scale: 1.1, config: {duration: 400}})
            p.then(() => imgRef.start({opacity: 1.0, scale: 1, config: {duration: 100}}))
        }, 100)
        dateRef.start({y: 0, opacity: 1.0})
        contentRef.start({opacity: 1.0, delay: 300})
    })

    return <animated.div className={addMdAble(css.history)} ref={historyRef} style={historyStyle}>
        <h3>{t('发展历程')}</h3>
        <div className={css.news}>
            <div className={css.content}>
                <animated.div className={css.date} style={dateStyle}>{events[currentIdx].date}</animated.div>
                <animated.div className={css.desc} style={contentStyle}>{t(events[currentIdx].msg)}</animated.div>
            </div>
            <animated.img alt="" src={events[currentIdx].pic} style={imgStyle} onLoad={onImgLoad}/>
            <Icon type={'xiangzuoanniu'} className={css.prev} onClick={prev}></Icon>
            <Icon type={'xiangyouanniu'} className={css.next} onClick={next}></Icon>
        </div>
        <div className={css['timeline-wrap']} onMouseEnter={() => setStop(true)} onMouseLeave={() => setStop(false)}>
            <ul className={css.timeline} ref={ref}>
                {events.map((event, idx) => <li
                    onClick={() => set(idx)}
                    className={idx < currentIdx? css.active: (idx === currentIdx ? css.current: '')}><span>{
                    yearAddon[idx]
                }</span></li>)}
            </ul>
            <div className={css.passed} style={{
                width, transform: `translateX(${0.5*block}px)`,
                transitionDuration: passedDurationAble ? '4.7s': '0s'
            }}></div>
        </div>
    </animated.div>
}