import { useTranslation } from 'react-i18next'
import css from './index.module.scss'
import { animated, useSpring } from '@react-spring/web'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import video from './normal video.mp4'
import { useState } from 'react'

export const Banner = () => {
    const { t } = useTranslation()
    
    const [h3style] = useSpring(() => ({
        from: {opacity: '0%'},
        to: {opacity: '100%'},
        delay: 300,
        config: {
            duration: 3000
        }
      }))
    
    const [divStyle] = useSpring(() => ({
        from: {opacity: '0%'},
        to: {opacity: '100%'},
        delay: 800,
        config: {
            duration: 2500
        }
    }))

    const [videoStyle, videoApi] = useSpring(() => ({
        from: {opacity: '0%'},
        to: {opacity: '100%'},
        pause: true,
        config: {
            duration: 800
        },
      }))

    const menuColorChangeRef = useMenuColor({
        color: '#fff',
        background: 'transparent'
    })

    return <div className={css.banner} ref={menuColorChangeRef}>
        <animated.video autoPlay muted loop onCanPlay={() => videoApi.resume()} style={videoStyle}>
            <source src={video}></source>
        </animated.video>
        <div className={css.mask}></div>
        <div className={css.title}>
            <animated.h3 style={h3style}>{t('以自动驾驶技术 让物流运输成本更低')}</animated.h3>
            <animated.div className={css.desc} style={divStyle}>{t('让人类出行更安全、更经济')}</animated.div>
        </div>
    </div>
}