import { useMemoizedFn } from "ahooks"
import classNames from "classnames"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const useEnAbleClass = (css: {[css: string]: string}) => {
    const { i18n } = useTranslation()
    const isEn = useMemo(() => i18n.resolvedLanguage === 'en', [i18n.resolvedLanguage])

    const addEnCls = useMemoizedFn((cls: string) => classNames({[cls]: true, [css.en]: isEn}))
    return { addEnCls }
}