import { useInView } from "@react-spring/web";
import { useDeepCompareEffect } from "ahooks";
import constate from "constate";
import { useState } from "react";

const [Provider, useColor] = constate(() => {
    const [style, setStyle] = useState<{
        color?: string,
        background?: string
    }>({
        color: '#fff',
        background: 'transparent'
    })
    return { style, setStyle }
})

export const useMenuColor = (style: {
    color?: string,
    background?: string
}) => {
    const [ ref, isInView ] = useInView({rootMargin: `80px 0px -${window.innerHeight - 80}px 0px`})
    const { style: nowStyle, setStyle } = useColor()
    useDeepCompareEffect(() => {
        if (isInView) {
            setTimeout(() => {
                setStyle(style)
            }, 100)
        }
    }, [isInView])
    return ref
}

export const MenuColorProvider = Provider

export const useMenu = useColor