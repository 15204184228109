import { animated, useChain, useSpring, useSpringRef, useTransition } from '@react-spring/web'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { useOpacityInView } from '../../hooks/useOpactiyInView.hook'
import css from './index.module.scss'
import companyIntroduct from './企业介绍小车.png'
import solidarity from './团结.png'
import keep from './坚持.png'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useResponsive } from 'ahooks'
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'

export const Intorduce = () => {
    const { t } = useTranslation()
    const menuColorRef = useMenuColor({color: '#1f1f1f', background: '#fff'})

    const [companyRef, companyStyle] = useOpacity()
    const companyLightRef = useSpringRef()
    const companyLightStyle = useSpring({
        ref: companyLightRef,
        from: {opacity: 0},
        to: [{opacity: 1}, {opacity: 0}, {opacity: 1}, {opacity: 0}, {opacity: 1}],
        config: {
            duration: 800
        }
    })
    useChain([companyRef, companyLightRef], [0.7, 1.3])

    const [teamRef, teamStyle] = useOpacityInView()

    const [keepRef, keepStyle] = useOpacityInView()

    const [corpRef, corpStyle] = useOpacityInView()

    const { addMdAble } = useMiddleAble(css)

    return <div className={addMdAble(css.intorduce)} ref={menuColorRef}>
        <animated.div style={companyStyle} className={css.company}>
        <h3>{t('企业介绍')}</h3>
        <div className={css['company-intorduct-pic-wrap']}>
            <img src={companyIntroduct} alt=""/>
            <animated.div className={css.light} style={companyLightStyle} />
        </div>
        <div className={css['company-desc']}>{t('白犀牛是一家L4自动驾驶公司，始终专注于城市公开道路上的自动驾驶产品和服务。以无人配送为起点，致力于让物流运输成本更低，让人类出行更安全、更经济。')}</div>
        </animated.div>
        <animated.div className={css['team-intorduce']} ref={teamRef} style={teamStyle}>
            <div className={css.content}>
                <h5>{t('团队介绍')}</h5>
                <div className={css.desc}>
                    {t('我们的创始团队与核心骨干研发人员，均来自国内外顶级自动驾驶企业，是国内最早从事自动驾驶技术研发和商业化运营的团队之一。核心研发团队规模近百人，主要来自百度、阿里巴巴、滴滴、字节跳动等知名互联网公司以及自动驾驶头部企业，70%以上为软件和硬件研发工程师，主要毕业于清华大学、北京大学、中国科学技术大学、芝加哥大学、香港大学、中国科学院等国内外知名院校以及科研机构，具备过硬的自动驾驶技术研发和应用示范能力。')}
                </div>
            </div>
        </animated.div>
        <div>
        <animated.div className={css.part} ref={keepRef} style={keepStyle}>
            <div className={css.block}>
                <h6>{t('坚持')}</h6>
                <div className={css.desc}>{t('我们崇尚坚持目标，专注于自动驾驶技术突破，在业务创新上保持坚定，推动自动驾驶技术更广泛的应用落地。')}</div>
            </div>
            <img src={keep} alt=""/>
        </animated.div>
        <animated.div className={css.part} ref={corpRef} style={corpStyle}>
            <img src={solidarity} alt=""/>
            <div className={css.block}>
                <h6>{t('团结')}</h6>
                <div className={css.desc}>{t('越是面对困难，我们越能紧密团结，相互合作、互相支持，充分发挥集体力量战胜困难。')}</div>
            </div>
        </animated.div>
        </div>
    </div>
}