import { useTranslation } from 'react-i18next'
import css from './index.module.scss'
import news01 from './img2/新闻封面01.png'
import news02 from './img2/新闻配图-02.png'
import news03 from './img2/新闻配图-03.png'
import news04 from './img2/新闻封面-04.png'
import news05 from './img2/新闻封面-05.png'
import news06 from './img2/新闻封面-06.png'
import news07 from './img2/新闻封面-07.png'
import news08 from './img2/新闻封面-08.png'
import news09 from './img2/新闻封面-09.png'
import news10 from './img2/新闻封面-10.png'
import news11 from './img2/新闻封面-11.png'
import news12 from './img2/新闻封面-12.png'
import news13 from './img2/新闻封面-13.png'
import news14 from './img2/新闻封面-14.png'
import news15 from './img2/新闻封面-15.png'
import news16 from './img2/新闻封面-16.png'
import { animated, useChain, useSpring, useSprings } from '@react-spring/web'
import LazyLoad from 'react-lazyload';
import { useOpacity } from '../hooks/useOpacity.hook'
import { useMenu, useMenuColor } from '../hooks/useMenuColor.hook'
import { useMemoizedFn } from 'ahooks'
import { useMemo, useState } from 'react'
import { Icon } from '../widget/Icon'
import { useEnAbleClass } from '../hooks/useEnAbleClass.hook'

import p20240530 from './img2/3.png'
import p20240425 from './img2/2.png'
import p20240130 from './img2/1.png'
import p20230926 from './img2/20230926-顺义合作-pc-2倍.e8ce059ca17a17f4ce5b.png'
import p202309262 from './img2/20230926-世界制造大会-pc-2倍.ff287531294cbc0dc2b9.png'
import p20230905 from './img2/20230905-重庆百货签约-PC-2倍.f5a2885f7a61194fb9bc.png'
const news = [
    {
        img: p20240530, title: '白犀牛获上海首批无人驾驶装备创新应用识别标牌', time: [2024, 5, 30],
        url: 'https://yrd.huanqiu.com/article/4I0JRFsnBut'
    }, {
        img: p20240425, title: '白犀牛参与项目再次入选国家智能交通先导应用试点', time: [2024, 4, 25],
        url: 'http://www.rmjtxw.com/news/kj/226953.html'
    }, {
        img: p20240130, title: '春节不打烊，白犀牛无人车日送快递1200件，让快递员安心返乡过年', time: [2024, 1, 30],
        url: 'https://auto.huanqiu.com/article/4GNg4xlwpXA'
    }, {
        img: p20230926, title: '顺义区政府携手白犀牛自动驾驶共建“智能网联汽车创新生态示范区”', time: [2023, 9, 26],
        url: 'https://wap.bjd.com.cn/news/2023/09/26/10576339.shtml'
    }, {
        img: p202309262, title: '白犀牛自动驾驶新车型Robovan首次亮相，参展2023世界制造大会', time: [2023, 9, 26],
        url: 'https://www.cet.com.cn/wzsy/kjzx/3451691.shtml'
    }, {
        img: p20230905, title: '白犀牛与重庆百货达成战略合作，共创零售配送新模式', time: [2023, 9, 5],
        url: 'http://www.cqsahngbao.cn/caijing/6733.html'
    },
    {img: news01, title: 'TUV南德与白犀牛达成全球战略合作，携手开拓国际市场', time: [2023, 2, 17],
     url: 'https://www.163.com/dy/article/HTPFE2SS051494RM.html'
    },
    {img: news02, title: '低速无人车产业将迎来“国家裁判”', time: [2022, 11, 12],
     url: 'https://mp.weixin.qq.com/s/MuGyiVAoBmtuzceD6OMeZg'
    },
    {img: news03, title: '最前线｜合肥首辆无人配送车白犀牛获商业运营牌照，低速无人车商业价值获官方认可', time: [2022, 9, 19],
     url: 'https://baijiahao.baidu.com/s?id=1744386761241883740&wfr=spider&for=pc'
    },
    {img: news04, title: '科创中心“核”动力｜白犀牛智达：新时代的配送员', time: [2022, 9, 5],
     url: 'https://baijiahao.baidu.com/s?id=1743128699646777580&wfr=spider&for=pc'
    },
    {img: news05, title: '白犀牛夏添：未来让无人配送车成为城市基础设施', time: [2022, 8, 17],
     url: 'https://gongyi.sohu.com/a/557976543_121388846'
    },
    {img: news06, title: '无人车都来了！永辉携白犀牛支持安亭保供，已完成2万件配送！', time: [2022, 4, 13],
     url: 'https://baijiahao.baidu.com/s?id=1729985020022667334&wfr=spider&for=pc'
    },
    {img: news07, title: '白犀牛通过首个L4级自动驾驶系统国际标准场景评估测试', time: [2022, 3, 18],
     url: 'http://www.jjckb.cn/2022-03/18/c_1310519448.htm'
    },
    {img: news08, title: '白犀牛无人配送车试跑合肥高新区', time: [2022, 3, 15],
     url: 'http://www.stdaily.com/index/kejixinwen/202203/54c02ab6566a41459ca68fce9accfad5.shtml'
    },
    {img: news09, title: '无人配送车白犀牛试跑合肥街头', time: [2022, 3, 13],
     url: 'https://baijiahao.baidu.com/s?id=1727158924170869789&wfr=spider&for=pc'
    },
    {img: news10, title: '白犀牛CEO朱磊：末端无人配送还没到抢市场的阶段｜探路2022', time: [2022, 1, 28],
     url: 'https://www.tmtpost.com/5994893.html'
    },
    {img: news11, title: '天虹超市 X 白犀牛 商超无人配送服务首次落地深圳', time: [2022, 1, 19],
     url: 'http://qiye.chinadaily.com.cn/a/202201/19/WS61e7b90ea3107be497a02f13.html'
    },
    {img: news12, title: '36氪首发｜无人配送公司白犀牛获5000万Pre A+轮融资，5年内无人车日活量达5000台', time: [2021, 12, 30],
     url: 'https://baijiahao.baidu.com/s?id=1720527109403686915&wfr=spider&for=pc'
    },
    {img: news13, title: '无人驾驶公司白犀牛获近千万美元融资', time: [2021, 7, 15],
     url: 'https://www.autohome.com.cn/news/202107/1177672.html'
    },
    {img: news14, title: '白犀牛联合永辉超市，推出零售无人配送', time: [2021, 10, 9],
     url: 'https://finance.sina.com.cn/tech/2020-10-09/doc-iivhuipp8626849.shtml'
    },
    {img: news15, title: '白犀牛无人车获辰韬资本追加投资', time: [2020, 3, 4],
     url: 'https://news.pedaily.cn/202003/452234.shtml'
    },
    {img: news16, title: '前百度自动驾驶核心成员加入「白犀牛」，研发末端即时配送无人车', time: [2020, 2, 19],
     url: 'https://36kr.com/p/1725100163073'
    },
]

const Card = ({img, title, time, url, idx}: {img: string, title: string, time: number[], url: string, idx: number}) => {
    const { t } = useTranslation()
    const { addEnCls } = useEnAbleClass(css)
    const [spring, api] = useSpring(() => ({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: (idx % 4)*100,
        pause: true
    }))
    const goTo = useMemoizedFn(() => window.open(url))
    return <li onClick={goTo}>
            <animated.div style={spring}>
                <LazyLoad offset={-200}>
                    <div ref={() => api.resume()}></div>
                </LazyLoad>
                <img src={img} alt={t(title)}/>
                <div className={addEnCls(css.title)}>{t(title)}</div>
                <div className={css.date}>{time[0]}{t('年')}{time[1]}{t('月')}{time[2]}{t('日')}</div>
            </animated.div>

    </li>
}

export const News = () => {
    const { t } = useTranslation()
    const [h3ref, h3Style] = useOpacity()

    const [bannerRef, bannerStyle] = useOpacity()

    useChain([bannerRef, h3ref], [0, 0.6])

    const newsBannerRef = useMenuColor({color: "#fff", background: 'transparent'})

    const ref = useMenuColor({color: "#1f1f1f", background: '#fff'})

    const [isAll, showMore] = useState(false)

    const allNews = useMemo(() => {
        if (isAll) return news;
        return news.slice(0, 8)
    }, [isAll])



    return <>
        <animated.div className={css['news-banner']} style={bannerStyle} ref={newsBannerRef} >
            <animated.h3 style={h3Style}>{t('新闻资讯')}</animated.h3>
        </animated.div>
        <ul className={css.center} ref={ref}>
            {allNews.map(({img, title, time, url}, idx) => <Card url={url} img={img} title={title} time={time} idx={idx}/>)}
            <div className={css['button-wrap']}>
                <div className={css.btn} onClick={() => showMore(!isAll)}>
                    {isAll ? t('收起'): t('展开')}
                    <Icon className={`${css['btn-icon']} ${isAll ? css.collapse: '' }`} type={isAll ? 'shouqi': 'zhankai'}></Icon>
                </div>
            </div>
        </ul>
    </>
}