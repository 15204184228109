import { Info } from "../Info"
import css from './index.module.scss'
import bev from './img/多目BEV-2合1.mp4'
import pic3 from './img/0001-2红绿灯检测识别1.5.png'
import pic4 from './img/0001-3感知地图自动更新1.5.png'

const items = [
    {title: 'BEV环视视觉模型', msg: '多个相机的联合视野覆盖车周身360°，经过多个相机数据输入并进行同步对齐，共同参与深度信息的估计。', video: bev},
    {title: 'Mapfree红绿灯检测识别', msg: '去除对高精度地图的依赖, 利用视觉模型从图像中直接识别出红绿灯的位置, 颜色和指向等信息。', pic: pic3},
    {title: '感知地图自动更新', msg: '将实时检测的背景障碍物信息在时空对齐后自动更新到感知地图当中，全过程不需要人为参与。', pic: pic4},
]

export const Perception = () => <Info
    title="感知模块"
    desc="随着3D感知与时序学习等技术的突破，白犀牛自动驾驶的感知模块能力边界也在不断扩大，已拓展到交通道路结构、场景的感知以及交通参与者的行为预测。为车辆后续的规划控制提供更加精确、可靠、安全的感知信息。"
    headerPlus={<>
        <div className={css['title-pic']}></div>
    </>}
    items={items}
    className={css.perception}
></Info>