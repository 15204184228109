import classNames from 'classnames'
import css from './explode.module.scss'
import p00 from './img/0.png'
import p01 from './img/1.png'
import p02 from './img/2.png'
import p03 from './img/3.png'
import p04 from './img/4.png'
import p05 from './img/5.png'
import p06 from './img/6.png'
import p07 from './img/7.png'
import p08 from './img/8.png'
import p09 from './img/9.png'
import p10 from './img/10.png'
import p11 from './img/11.png'
import p12 from './img/12.png'
import p13 from './img/13.png'
import p14 from './img/14.png'

import { useState } from 'react'
import { useMemoizedFn, useTimeout } from 'ahooks'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { animated, useChain } from '@react-spring/web'

export const Explode = () => {
    const [ state, setState ] = useState(false)
    const [imgRef, imgStyle] = useOpacity(800)

    useChain([imgRef], [0.7])

    useTimeout(() => {
        setState(true)
    }, 1000)

    useTimeout(() => {
        setState(false)
    }, 2000)

    const toggle = useMemoizedFn(() => {
        setState(!state)
    })

    return <animated.div className={classNames({
        [css.explode]: true,
        [css.animated]: state
    })} style={imgStyle} onClick={toggle}>
        <img src={p00} alt={''} className={css.img0}/>
        <img src={p01} alt={''} className={css.img1}/>
        <img src={p02} alt={''} className={css.img2}/>
        <img src={p03} alt={''} className={css.img3}/>
        <img src={p04} alt={''} className={css.img4}/>
        <img src={p06} alt={''} className={css.img6}/>
        <img src={p05} alt={''} className={css.img5}/>
        <img src={p07} alt={''} className={css.img7}/>
        <img src={p08} alt={''} className={css.img8}/>
        <img src={p09} alt={''} className={css.img9}/>
        <img src={p10} alt={''} className={css.img10}/>
        <img src={p11} alt={''} className={css.img11}/>
        <img src={p12} alt={''} className={css.img12}/>
        <img src={p13} alt={''} className={css.img13}/>
        <img src={p14} alt={''} className={css.img14}/>
    </animated.div>
}