import { useTranslation } from 'react-i18next'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { Point } from './Point'
import css from './index.module.scss'
import { useMemo, useState } from 'react'
import { useInterval } from 'ahooks'
import { useInView } from '@react-spring/web'

const data = [
    {name: '北京', top: 189, left: 984},
    {name: '天津', top: 215, left: 1027},
    {name: '唐山', top: 240, left: 1003},
    {name: '银川', top: 227, left: 750},
    {name: '兰州', top: 283, left: 692},
    {name: '西安', top: 327, left: 814},
    {name: '许昌', top: 342, left: 947},
    {name: '青岛', top: 269, left: 1120},
    {name: '成都', top: 427, left: 661},
    {name: '重庆', top: 443, left: 794},
    {name: '武汉', top: 430, left: 1005},
    {name: '合肥', top: 412, left: 1072},
    {name: '无锡', top: 375, left: 1148},
    {name: '苏州', top: 407, left: 1159},
    {name: '上海', top: 418, left: 1241},
    {name: '芜湖', top: 436, left: 1095},
    {name: '杭州', top: 449, left: 1159},
    {name: '长沙', top: 553, left: 971},
    {name: '福州', top: 603, left: 1178},
    {name: '广州', top: 664, left: 984},
    {name: '厦门', top: 656, left: 1140},
    {name: '深圳', top: 695, left: 1030},
    {name: '香港', top: 720, left: 1055},
    {name: '珠海', top: 735, left: 1000},
    {name: '琼海', top: 843, left: 923},
]

export const City = () => {
    const ref = useMenuColor({
        color: '#fff',
        background: '#222'
    })
    const { t } = useTranslation()
    const [count, setCount] = useState(0)

    const [mapRef, inView] = useInView({once: true, rootMargin: '-50%'})

    useInterval(() => {
        inView && setCount(count+1)
    }, 100)

    const arr = useMemo(() => data.slice(0, count), [count])

    return <div className={css.city} ref={ref}>
        <div className={css['map-wrap']} ref={mapRef}>
            <div className={css.map}>
                <div className={css.title}>{t('合作城市')}</div>
                {
                    arr.map(item => <Point top={item.top} left={item.left} name={item.name}></Point>)
                }
            </div>
        </div>
    </div>
}