import { useMemoizedFn, useResponsive } from "ahooks"
import classNames from "classnames"

export const useMiddleAble = (css: any) => {
    const { large } = useResponsive()

    const addMdAble = useMemoizedFn(cls => {
        return classNames({[cls]: true, [css.middle]: !large})
    })

    return { addMdAble }
}