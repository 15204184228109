import { useTranslation } from 'react-i18next'
import css from './index.module.scss'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { animated, useChain, useInView, useSpring } from '@react-spring/web'
import { useDeepCompareEffect, useMemoizedFn } from 'ahooks'
import { useOpacity } from '../../hooks/useOpacity.hook'

export const JoinUs = () => {
    const { t } = useTranslation()
    const ref = useMenuColor({color: '#171717', background: '#fff'})
    
    const [ vehicleRef, isInView ] = useInView({rootMargin: `0px 0px -600px 0px`})

    const [vehicleStyle, api] = useSpring(() => ({
        from: { opacity: 0, x: 0 },
        to: { opacity: 1, x: 0 },
    }))

    useDeepCompareEffect(() => {
        if (isInView) {
            api.set({x: 200})
            api.start({opacity: 1, x: 0})
        } else {
            api.start({opacity: 0})
        }
    }, [isInView])

    const [titleRef, titleIsInView] = useInView({rootMargin: `0px 0px -300px 0px`})

    const [h3Ref, h3Style] = useOpacity()

    const [ulRef, ulStyle] = useOpacity()

    const [imgRef, imgStyle] = useOpacity()

    const goTo = useMemoizedFn(url => window.open(url))

    useChain(titleIsInView ? [h3Ref, ulRef, imgRef]: [], [0.3, 0.8, 1.0])

    return <div className={css['join-us-wrap']} ref={ref}>
        <animated.div className={css.car} ref={vehicleRef} style={vehicleStyle}/>
        <div className={css['recruit-wrap']}>
            <animated.h3 ref={titleRef} style={h3Style}>{t('加入我们')}</animated.h3>
            <animated.ul style={ulStyle}>
                <h6>{t('招聘岗位')}</h6>
                <li onClick={() => goTo('https://www.zhipin.com/job_detail/655676675ebaf8211nF-2du6EFFQ.html?lid=4kooenojeDD.search.15&securityId=ZDR4h2gkIqpoW-213ic5mX_7uRXOUfn-qJbBgURv4htutMq1w_N8vOnQc9o54CAeYwItSWdDPQ2668nXFJe7JKwQ_aFzhCXszXlM4YSDQIpp-HETUw96uS_mPH2fZx0KA0oYfe0-cfZBkTHEAkouSBc~&sessionId=')}>{t('自动驾驶规划算法工程师')}</li>
                <li onClick={() => goTo('https://www.zhipin.com/job_detail/b02dd649cb4b8f271nF_2d65FldV.html?lid=4kAGbzlBR4y.search.13&securityId=QVSASWqz4l8xp-w192_2PFznU6xnU9RmJcNfaY9G-tJ9P_5NkT2fzLFqracqfm0MPNlPYqOycwWODQCSOF1tLFCC1zYf1A-0GcY8G2XyoFI13kYL-Fpifcp4_1X8iHNRTWvnEXpWKYfAiUK8fi0jM_A~&sessionId=')}>{t('自动驾驶定位地图算法工程师')}</li>
                <li onClick={() => goTo('https://www.zhipin.com/job_detail/cc8e19b2c0ffb5e11nF-2du5E1JQ.html?lid=4kAGbzlBR4y.search.30&securityId=TLeKokM7S0Oux-11f_Hm7RGhfZpb-kSdIgZyR0ZWYUfEQdOUlc3tJbcEUaIWanbYctOuAeuWE-58C7Ziodsga4n4Qi5OZMicdNdJVaGfKMW0gZ5ICUQdADQ2IH5oofsUJlYLhuakxhy2psj9D7MXAmk~&sessionId=')}>{t('自动驾驶感知算法工程师')}</li>
                <li onClick={() => goTo('https://www.zhipin.com/job_detail/8d835e4774b5f2641nBy2N-8EFFS.html?lid=4kAGbzlBR4y.search.34&securityId=CtXdGY4Mg2BQL-S1mWEb3vA-c_5iKa_Mc47qAK15Ews9XCzLMnGK_CmAJheHrB8sn9tZYBUdAfGQIBYpyYnVlRKJBKEpL_4xU_BAIEuHM_-zV-ytuvKnetkaYGgpJBtonw6pxwB-DQT7ia5gJSetatk%7E&sessionId=')}>{t('自动驾驶车载系统工程师')}</li>
                <li onClick={() => goTo('https://www.zhipin.com/job_detail/19972d042c7b66961nx72tu0EFtX.html?lid=4k9Icg3o35L.search.32&securityId=ZfaykZjQO0p4L-C1T-dA71mraB8b7bklOV1ZRTK8BqdFPd_-tiU49kvM74LZ7K5CKz7RWh_BV3Oe9YQYHtcemE-4rpeA87Ms0NNJVByt2EvZogFdtLrfZey0WfKBZzNeVf6Tkg8kDfSMhH_BjQwYPWg~&sessionId=')}>{t('自动驾驶电子工程师')}</li>
            </animated.ul>
            <div className={css.mail}>{t('简历投递')}&nbsp;&nbsp;zhaopin@rino.ai</div>
            <animated.div style={imgStyle} className={css['join-us-bg']}></animated.div>
        </div>
    </div>
}