import { animated, useChain } from '@react-spring/web'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { useOpacity } from '../../hooks/useOpacity.hook'
import css from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { useResponsive } from 'ahooks'

export const Banner = () => {
    const menuColorRef = useMenuColor({color: '#fff', background: 'transparent'})

    const { t } = useTranslation()

    const [bgRef, bgStyle] = useOpacity()
    const [h3Ref, h3Style] = useOpacity()

    useChain([bgRef, h3Ref], [0, 0.5])

    return <animated.div className={css.banner} style={bgStyle}>
        <div className={css.content} ref={menuColorRef}>
            <animated.h3 style={h3Style}>{t('关于我们')}</animated.h3>
        </div>
    </animated.div>
}