import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Index } from './Index/index';
import reportWebVitals from './reportWebVitals';
import 'reset-css';
import './i18n';
import { configResponsive } from 'ahooks';

configResponsive({
  small: 0,
  middle: 1200,
  large: 1440,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
