import css from './index.module.scss'
import { Info } from "../Info"
import pic1 from './img2/决策规划-图1-1.5.png'
import pic2 from './img2/决策规划-02-1.5.png'
import pic3 from './img2/决策规划-图3-1.5.png'
import pic4 from './img2/决策规划-图4-1.5.png'

const items = [{
    title: '自适应时空联合规划', msg: '结合复杂路况场景，在时空域统一规划更符合人类驾驶习惯的车辆运行轨迹。',
    pic: pic1
}, {
    title: '不确定性规划', msg: '引入概率模型，充分适配上下游模块的数据不确定性。',
    pic: pic2
}, {
    title: '\u00A0\u00A0强化学习/逆强化学习\u00A0\u00A0 博弈决策', msg: '车辆通过和环境的交互，来学习人类司机驾驶习惯，灵活处理博弈场景，挺高车辆通行效率。',
    pic: pic3
}, {
    title: '非线性反馈控制器', msg: '采用非线性反馈控制模型，在大曲线等复杂工况下，显著提高车辆控制精度。',
    pic: pic4
}]

export const Planning = () => <Info
    title="决策规划"
    desc="在自动驾驶系统中，决策规划模块相当于人类驾驶员的大脑，白犀牛自动驾驶系统在理解感知以及定位等上游信息后，帮助车辆在复杂路况中做出安全、可靠、最优的路径规划和驾驶决策，进一步实现高精度的车辆控制。"
    headerPlus={<div className={css['title-pic']}>
        <div className={css.car}></div>
    </div>}
    items={items}
    className={css.planning}
/>