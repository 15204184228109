import { useTranslation } from 'react-i18next'
import css from './index.module.scss'
import { useMemo } from 'react'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { useOpacity } from '../../hooks/useOpacity.hook'
import { animated, useChain, useInView } from '@react-spring/web'
import { useMiddleAble } from '../../hooks/useMiddleAble.hook'

export const TestReport = () => {
    const { t, i18n } = useTranslation()

    const [ref, inView] = useInView({once: true, rootMargin: '-50%'})

    const menuColorChangeRef = useMenuColor({
        color: '#fff',
        background: '#174CBA'
    })

    const [ titleRef, titleStyle ] = useOpacity()
    const [ descRef, descStyle ] = useOpacity()
    const [ laiyinRef, laiyinStyle ] = useOpacity()

    useChain(inView ? [titleRef, descRef, laiyinRef]: [], [0, 0.5, 1.0])

    const { addMdAble } = useMiddleAble(css)

    const isEn = useMemo(() => i18n.resolvedLanguage === 'en', [i18n.resolvedLanguage])
    return <div className={addMdAble(css.bg)} ref={menuColorChangeRef}>
        <div className={css['test-report']} ref={ref}>
            <animated.h5 style={titleStyle} className={isEn ? css.en: ''}>{t('大中华区首个通过L4级自动驾驶系统国际标准ISO22737 自动驾驶车辆场景评估测试企业')}</animated.h5>
            <animated.div style={descStyle} className={css.desc}>{t('ISO22737 是首个针对L4级自动驾驶系统的国际标准。ISO22737 主要针对预定线路LSAD自动驾驶系统（automated driving for predefined routes），并对其性能要求、系统要求和性能测试程序提出了相应的严格规定。ISO22737 的出现为制造商、运营商、用户及监管机构都提供了指导，以确保自动驾驶系统的安全部署和安全运行。')}</animated.div>
            <animated.div style={laiyinStyle} className={css.laiyin}></animated.div>
        </div>
    </div>
}