import css from './index.module.scss'
import dada from './img/达达快送.png'
import darunfa from './img/大润发.png'
import dingdang from './img/叮当快药.png'
import hema from './img/盒马.png'
import huolala from './img/货拉拉.png'
import daojia from './img/京东到家.png'
import kuaigou from './img/快狗打车.png'
import express from './img/顺丰速运.png'
import expressCity from './img/顺丰同城.png'
import legend from './img/生鲜传奇.png'
import rainbow from './img/天虹.png'
import yonghui from './img/永辉超市.png'
import zto from './img/中通快递.png'
import chentao from './img/辰韬资本.png'
import changan from './img/长安.png'
import jishi from './img/基石资本.png'
import suodao from './img/索道投资.png'
import liner from './img/线性资本.png'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import { useDeepCompareEffect, useInterval, useMemoizedFn, useThrottleFn } from 'ahooks'
import { Icon } from '../../widget/Icon'
import { useInView } from '@react-spring/web'

const partners = [
    dada,
    darunfa,
    dingdang,
    hema,
    huolala,
    daojia,
    kuaigou,
    express,
    expressCity,
    legend,
    rainbow,
    yonghui,
    zto,
    chentao,
    changan,
    jishi,
    suodao,
    liner,

    dada,
    darunfa,
    dingdang,
    hema,
]
const Card: React.FC<{src: string}> = ({src}) => <li style={{backgroundImage: `url(${src})`}}></li>

export const Partner = () => {
    const { t } = useTranslation()
    const [idx, setIdx] = useState(0)
    const [isNext, setIsNext] = useState(true)
    const [able, setAble] = useState(true)
    const [autoAble, setAutoAble] = useState(false)

    const [eleRef, inView] = useInView({once: true, rootMargin: '-350px'})

    const ref = useRef<HTMLUListElement>(null)

    const prev = useMemoizedFn(() => {
        if (!able) return
        const i = idx-1
        if (i < 0) {
            setIdx(partners.length - 4)
            setAble(false)
            setTimeout(() => {
                setIdx(partners.length - 5)
                setAble(true)
            }, 100)
        } else {
            setIdx(i)
        }
        setIsNext(false)
    })

    const {run: next} = useThrottleFn(() => {
        if (!able) return
        setIdx(idx + 1)
        if (partners.length === idx + 5) {
            setAble(false)
            setTimeout(() => {
                setIdx(0)
                setAble(true)
            }, 500)
        }
        setIsNext(true)
    }, {wait: 100})

    useInterval(() => {
        if (autoAble) next()
    }, 3000)

    useDeepCompareEffect(() => {
        if (inView) {
            setAutoAble(true)
        }
    }, [inView])

    return <div className={css['partner-wrap']} ref={eleRef}>
        <div className={css.partner}  onMouseEnter={() => setAutoAble(false)} onMouseLeave={() => setAutoAble(true)}>
            <div className={css.title}>{t('合作伙伴')}</div>
            {/* <button className={css.prev} onClick={() => prev()}></button> */}
            <Icon type="xiangzuoanniu" className={css.prev} onClick={() => prev()}/>
            <div className={css.wrap}>
                <ul ref={ref} style={{transform: `translateX(-${idx * 217}px)`, transition: (isNext ? idx === 0: idx === partners.length - 4) ? 'none': ''}}>
                    {partners.map(partner => <Card src={partner} />)}
                </ul>
            </div>
            <Icon type="xiangyouanniu" className={css.next} onClick={() => next()}/>
        </div>
    </div>
}