import { animated, useChain, useScroll, useSpring } from '@react-spring/web'
import { useOpacity } from '../../hooks/useOpacity.hook'
import css from './index.module.scss'
import pic from './服务中心-头图.png'
import { useTranslation } from 'react-i18next'
import { useEnAbleClass } from '../../hooks/useEnAbleClass.hook'
import { useMemo, useRef } from 'react'
import { useResponsive, useThrottleFn } from 'ahooks'
import { useMenuColor } from '../../hooks/useMenuColor.hook'

export const Banner = () => {
    const newsBannerRef = useMenuColor({color: "#fff", background: '#232323'})
    const { t } = useTranslation()
    const [h3Ref, h3Style] = useOpacity()
    const [imgRef, imgStyle] = useOpacity()
    const [descRef, descStyle] = useOpacity()
    const imgEleRef = useRef<HTMLImageElement>(null)
    const { large } = useResponsive()

    
    const clipWidth = useMemo(() => (window.innerWidth - (large ? 1200: 800)) / 2, [large])

    const [imgWidthStyle, imgWidthRef ] = useSpring(() => ({
        from: { clipPath: `inset(0 ${clipWidth}px 0 ${clipWidth}px round 20px)` },
        config: {
            duration: 300
        }
    }))
    
    const { addEnCls }  = useEnAbleClass(css)

    const {run: updateWidth} = useThrottleFn(() => {
        const { current } = imgEleRef
        // if (init) return
        if (current) {
            const { top } = current.getBoundingClientRect()
            if (top <= 0) {
                imgWidthRef.start({
                    to: { clipPath: `inset(0 0 0 0 round 0)` },
                    config: {
                        duration: 100
                    }
                })
            } else {
                let x = top / 433
                if (x > 1) x = 1
                imgWidthRef.start({
                    to: { clipPath: `inset(0 ${x*clipWidth}px 0 ${x*clipWidth}px round ${x === 0? 0: 20}px)` },
                    config: {
                        duration: 100
                    }
                })
            }
        }
    }, {wait: 100, leading: true})

    useScroll({onChange: updateWidth})



    useChain([h3Ref, descRef, imgRef], [0, 0.2, 0.4])

    return <animated.div className={css['banner-wrap']} ref={newsBannerRef}>
        <div className={css.banner}>
            <animated.h3 style={h3Style}>{t('服务中心')}</animated.h3>
            <animated.div className={`${addEnCls(css.desc)} ${large ? '': css.middle}`} style={descStyle}>{t('我们提供城市物流领域先进的自动驾驶产品和综合解决方案，可满足货运、配送和其它城市服务中多样化且无处不在的自动驾驶需求。')}</animated.div>
            <animated.img ref={imgEleRef} src={pic} alt="" style={{...imgStyle, ...imgWidthStyle}}></animated.img>
        </div>
    </animated.div>
}