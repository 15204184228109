import { useSpring, useSpringRef } from "@react-spring/web"
import { useMount } from "ahooks"

export const useScale = (delay: number) => {
    const ref = useSpringRef()
    const [style, api] = useSpring(() => ({
        from: { opacity: 0 },
        to: [
            { opacity: 1 },
            { opacity: 0 }
        ],
        delay: delay ?? 0,
        config: {
            duration: 800
        },
        loop: true,
    }))

    useMount(() => api())
    return [ref, style] as [typeof ref, typeof style]
}