import { useTranslation } from 'react-i18next';
import css from './header.module.scss'
import { useDeepCompareEffect, useMemoizedFn, useMount, usePrevious, useScroll } from 'ahooks';
import { useMenu } from '../../hooks/useMenuColor.hook';
import { useMemo, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";

export const Header = () => {
    const { t, i18n } = useTranslation();

    const useEnglish = useMemoizedFn(() => {
        i18n.changeLanguage('en-US')
    })

    const useChinese = useMemoizedFn(() => {
        i18n.changeLanguage('zh-CN')
    })

    //useMount(useEnglish)

    const { style } = useMenu()

    const { top = 0 } = useScroll() ?? {}
    const prevTop = usePrevious(top)
    const [visible, setVisible] = useState(true)

    useDeepCompareEffect(() => {
        if (!prevTop) return
        if (top < prevTop) {
            setVisible(true)
        } else {
            setVisible(false)
        }
        if (top <= 0) {
            setVisible(true)
        }
    }, [top, prevTop])

    const [titleStyle, titleRef] = useSpring(() => ({
        background: 'transparent',
        y: '0',
        config: {
            duration: 500
        }
    }))

    useDeepCompareEffect(() => {
        titleRef.start({background: style.background, config: {
            duration: 100
        }})
    }, [style.background])

    useDeepCompareEffect(() => {
        if (visible) {
            titleRef.start({y: '0'})
        } else {
            titleRef.start({y: '-100%'})
        }
    }, [visible])

    const isEn = useMemo(() => i18n.resolvedLanguage === 'en', [i18n.resolvedLanguage])

    const isWhite = useMemo(() => {
        return style.color === '#fff' ? true: false
    }, [style.color])

    const resetScroll = useMemoizedFn(() => {
        window.scrollTo({left: 0, top: 0})
    })

    return <animated.div className={css.bg} style={titleStyle}>
        <div className={css.header} style={{color: style.color}}>
        <Link to="/" onClick={resetScroll}>
            <div className={`${css.logo} ${isWhite ? '': css['logo-black']}`}></div>
        </Link>
        <ul className={css.menu}>
            <li><NavLink onClick={resetScroll} to="/" className={({isActive}) => isActive ? css.active: ''}>{t('首页')}</NavLink></li>
            <li><NavLink onClick={resetScroll} to="/technology" className={({isActive}) => isActive ? css.active: ''}>{t('核心技术')}</NavLink></li>
            <li><NavLink onClick={resetScroll} to="/service" className={({isActive}) => isActive ? css.active: ''}>{t('服务中心')}</NavLink></li>
            <li><NavLink onClick={resetScroll} to="/news" className={({isActive}) => isActive ? css.active: ''}>{t('新闻资讯')}</NavLink></li>
            <li><NavLink onClick={resetScroll} to="/about-us" className={({isActive}) => isActive ? css.active: ''}>{t('关于我们')}</NavLink></li>
            <li><NavLink onClick={resetScroll} to="/contact" className={({isActive}) => isActive ? css.active: ''}>{t('联系我们')}</NavLink></li>
        </ul>
        <div className={css.language}> <span className={isEn ? '': css.active} onClick={useChinese}>中文</span> / <span onClick={useEnglish} className={isEn ? css.active: ''}>En</span></div>
    </div>
    </animated.div>
}