import { animated, useChain } from '@react-spring/web'
import { useMenuColor } from '../../hooks/useMenuColor.hook'
import { useOpacity } from '../../hooks/useOpacity.hook'
import css from './index.module.scss'
import { useTranslation } from 'react-i18next'

const Widget = ({iconClass, name, value, style}: {iconClass: string, name: string, value: string, style?: any}) => <animated.div className={css.part} style={style}>
    <div className={iconClass}></div>
    <div className={css.name}>{name}</div>
    <div>{value}</div>
</animated.div>

export const ContactBanner = () => {
    const { t } = useTranslation()
    const ref = useMenuColor({ color: '#fff', background: 'transparent' })
    const [h3Ref, h3Style] = useOpacity()
    const [telRef, telStyle] = useOpacity()
    const [mailRef, mailStyle] = useOpacity()
    const [addressRef, addressStyle] = useOpacity()

    useChain([h3Ref, telRef, mailRef, addressRef], [ 0.1, 0.23, 0.33, 0.43])

    return <div className={css['contact-banner']} ref={ref}>
        <div className={css.contact}>
            <animated.h3 style={h3Style}>{t('联系我们')}</animated.h3>
            <div className={css.block}></div>
            <div className={css.way}>
                <Widget style={telStyle} iconClass={css['icon-tel']} name={t('联系电话')} value="400-113-0423"/>
                <Widget style={mailStyle} iconClass={css['icon-mail']} name={t('合作邮箱')} value="contacts@rino.ai"/>
                <Widget style={addressStyle} iconClass={css['icon-address']} name={t('总部地址')} value={t('北京市 海淀区 花园东路11号 泰兴大厦 9层')}/>
            </div>
        </div>
    </div>
}