import { Info } from "../Info"
import css from './index.module.scss'
import pic1 from './img2/数据处理板1.5.png'
import pic2 from './img2/计算平台1.5.png'
import pic3 from './img2/定位盒子1.5.png'
import pic4 from './img2/传感器套件1.5.png'
import pic5 from './img2/摄像头自清洁1.5.png'
import pic6 from './img2/智能人车交互1.5.png'

const items = [{
    title: '数据处理板', msg: 'LIDAR与CAMERA采集、同步、预处理板，提高计算平台实时性。',
    pic: pic1
}, {
    title: '计算平台', msg: '自研高性能、高可靠性、低功耗、低成本计算平台。',
    pic: pic2
}, {
    title: '定位盒子', msg: '自研融合了IMU、CANBUS、GNSS、LIDAR 和CAMERA信息的高精度融合定位设备。',
    pic: pic3
}, {
    title: '传感器套件', msg: '集成了电器设备、雷达、摄像头等多类传感器设备，易于快速部署。',
    pic: pic4
}, {
    title: '摄像头自清洁', msg: '研发了应对雨、雪等恶劣天气的气刀清洁系统，加强全天候运营能力。',
    pic: pic5
}, {
    title: '智能人车交互', msg: '根据场景语音或者灯光提示行人与车辆，并且可以实时语音对话。',
    pic: pic6
}]

export const Hardware = () => <Info
    title="自研硬件"
    desc="硬件平台的设计直接决定了自动驾驶系统对环境的理解能力，白犀牛自主研发的数据处理与计算平台，结合性能卓越的传感器套件，保障了白犀牛自动驾驶的高性能，高可靠性和高适配性。"
    headerPlus={<div className={css['title-pic']}>
        <div className={css.car}></div>
    </div>}
    items={items}
    className={css.hardware}
></Info>